import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import VideoPlayIcon from 'components/atoms/VideoPlayIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import ResourceTile from 'components/molecules/ResourceTile';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const VideosContent = ({ resources = [], newsletterCta, cta }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className="u-container pt-32 md:pt-48 mb-20 md:mb-24 px-6 lg:px-md xl:px-0">
        <Link
          className="inline-flex items-center text-berry font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
          to="/resources"
        >
          <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
          Resources
        </Link>
        <h1 className="u-h1 mt-6 md:mt-8 mb-12 md:mb-20">Videos</h1>

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 lg:pr-24 mb-8 lg:mb-0">
            {resources.length > 0 && (
              <ResourceTile
                type="video"
                title={resources[0].title}
                image={resources[0].thumbnailUrl}
                url={resources[0].url}
                videoIconInverted
              />
            )}
          </div>
          <div className="w-full lg:w-1/2 space-y-9">
            {resources
              .slice(1, 4)
              .map(({ url, thumbnailUrl, title }, index) => (
                <Link
                  to={url}
                  key={index}
                  className="flex items-center transition-opacity duration-200 ease-in-out hover:opacity-50"
                >
                  <div className="w-52 relative mr-9">
                    <img
                      src={thumbnailUrl}
                      alt={title}
                      className="w-full h-auto"
                    />
                    <div className="absolute inset-0 flex justify-center items-center">
                      <VideoPlayIcon
                        background="text-white"
                        foreground="text-dark-grey"
                        className="w-auto h-10"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <ResourceTypeTag type="video" />
                    </div>
                    <h2 className="text-lg font-semibold tracking-tighter leading-tight">
                      {title}
                    </h2>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>

      <NewsletterCtaSection
        headline={newsletterCta?.headline}
        background="bg-berry text-white"
      />

      {resources.length > 4 && (
        <ResourceGrid
          cols={3}
          videoIconInverted
          resources={resources.slice(4, showAll ? undefined : 13)}
        />
      )}

      {resources.length > 13 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'Is Your Store Ready for Labor Day Weekend?',
    date: '2020-05-06T07:00:32.311Z',
    thumbnailUrl: '/uploads/video-labor-day.jpg',
    url: '/videos',
    type: 'video',
    excerpt:
      'Mauris aliquet varius nec vitae. Malesuada parturient aliquet auctor nunc eleifend suscipit viverra. Mattis facilisis viverra turpis.',
  },
];

export const VideosPreview = ({ entry }) => {
  return (
    <VideosContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const VideosPage = ({
  data: {
    markdownRemark: { frontmatter },
    videos,
  },
}) => {
  const { seo } = frontmatter;

  const resources = videos.edges.map(({ node }) => {
    const { title, date, heroImage, slug, description } =
      node.frontmatter || {};

    return {
      title,
      date,
      thumbnailUrl: heroImage,
      url: `/videos/${slug}`,
      type: 'video',
      excerpt: description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <VideosContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query VideosPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/videos/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    videos: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "video" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default VideosPage;
